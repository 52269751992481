import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import { Select, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useGlobalContext } from "../../../context";
import useParamsFromUrl from "../hooks/useParamsFromUrl";
import reloadAds from "../../../utils/getUrlFromParams";

const ORDERING_OPTIONS = [
  { value: "-preferences", label: "Tinkamiausi man" },
  { value: "price", label: "Pigiausi viršuje" },
  { value: "-price", label: "Brangiausi viršuje" },
  { value: "-list_date", label: "Naujausi viršuje" },
  { value: "list_date", label: "Seniausi viršuje" },
  // { value: "distance", label: "Arčiausiai man aktualių vietų" },
];

const menuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

function SortSelector() {
  const { dispatch, dispatchRouter } = useGlobalContext();
  const params = useParamsFromUrl();
  const [orderingValue, setOrderingValue] = useState(
    params.ordering || "-list_date"
  );
  const history = useHistory();

  useEffect(() => {
    setOrderingValue(params.ordering || "-list_date");
  }, [params.ordering]);

  function handleChange(e) {
    let ordering = e.target.value;
    if (ordering === "distance" && !params?.place_coords) {
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { drawPlacesMode: true },
      });
    }
    let newParams = { ...params, ordering };
    delete newParams.page;
    reloadAds(newParams, dispatch, history);
    setOrderingValue(ordering);
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { mouseOverAd: null },
    });
  }

  // console.log("orderingValue from SortSelector", orderingValue);

  return (
    <>
      <FormControl size="small" sx={{ mr: 1 }}>
        <Select
          value={orderingValue}
          onChange={handleChange}
          MenuProps={menuProps}
          inputProps={{
            sx: {
              fontSize: { xs: 13, sm: 13.5 },
              p: 0.7,
              pl: 1,
              // border: "none",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "solid grey 1px",
            },
          }}
        >
          {ORDERING_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value} dense>
              <Typography variant="body2">{option.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default SortSelector;
