import React, { useState } from "react";
import { FormControl, Select, MenuItem, Box, Typography } from "@mui/material";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import useParamsFromUrl from "../hooks/useParamsFromUrl";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../../context";
import reloadAds from "../../../utils/getUrlFromParams";

const menuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  //   getContentAnchorEl: null,
};

function AdsPerPageSelector() {
  const history = useHistory();
  const { dispatch } = useGlobalContext();
  const params = useParamsFromUrl();
  const [adsPerPage, setAdsPerPage] = useState(params.ads_per_page || 20);

  function handleChange(event) {
    let adsPerPage = event.target.value;
    let newParams = { ...params, ads_per_page: adsPerPage };
    delete newParams.page;
    reloadAds(newParams, dispatch, history);
    setAdsPerPage(adsPerPage);
    localStorage.setItem("resultsPerPage", adsPerPage);
  }

  return (
    <FormControl size="small">
      <Select
        value={adsPerPage}
        onChange={handleChange}
        inputProps={{
          sx: { fontSize: 14, p: 0.5, pl: 1 },
        }}
        renderValue={() => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormatListNumberedIcon fontSize="small" sx={{ mr: 0.5 }} />
            <Typography variant="body2">
              Rodyti {adsPerPage} skelbimų puslapyje
            </Typography>
          </Box>
        )}
        MenuProps={menuProps}
      >
        <MenuItem value={10} dense>
          Rodyti 10 skelbimų puslapyje
        </MenuItem>
        <MenuItem value={20} dense>
          Rodyti 20 skelbimų puslapyje
        </MenuItem>
        {/* <MenuItem value={50} dense>
          Rodyti 50 skelbimų puslapyje
        </MenuItem> */}
      </Select>
    </FormControl>
  );
}

export default AdsPerPageSelector;
