import React, { useEffect } from "react";
import { useGlobalContext } from "../../../context";
import {
  fetchMunicipalityOptions,
  fetchStreetOptions,
  fetchSuburbOptions,
  fetchTownOptions,
} from "../fetchOrSendAdDraftData";

function FormStep1DataProvider({ children }) {
  const { adFormState, dispatchAdForm } = useGlobalContext();
  const ad = adFormState.adDraft;

  // initial load of location options
  useEffect(() => {
    if (
      !adFormState.municipalityOptionsLoaded ||
      adFormState.municipalityOptions.length === 0
    ) {
      let municipality = ad?.properties?.municipality?.id;
      let town = ad?.properties?.town?.id;
      fetchMunicipalityOptions(dispatchAdForm);
      if (!!municipality) {
        let form = { municipality };
        fetchTownOptions(dispatchAdForm, form);
      }
      if (!!town) {
        let form = { municipality, town };
        fetchStreetOptions(dispatchAdForm, form);
        fetchSuburbOptions(dispatchAdForm, form);
      }
    }
  }, []);

  // console.log("adFormState", adFormState);

  return <>{adFormState.municipalityOptionsLoaded && children}</>;
}

export default FormStep1DataProvider;
