import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import PreferenceChip from "./PreferenceFilter/PreferenceChip";
import SortSelector from "./SortSelector";
import { useGlobalContext } from "../../../context";
import SaveIcon from "@mui/icons-material/Save";
import useParamsFromUrl from "../hooks/useParamsFromUrl";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useEffect, useState } from "react";
import reloadAds from "../../../utils/getUrlFromParams";
import { useHistory } from "react-router-dom";
import { blue, red } from "@mui/material/colors";
import SaveSearchDialog from "../MainAppToolbar/SaveSearch/SaveSearchDialog";
import PageSelector from "./PageSelector";

const adNames = {
  flat_sale: "parduodamų butų",
  flat_rent: "nuomojamų butų",
  house_sale: "parduodamų namų",
  house_rent: "nuomojamų namų",
  land_sale: "parduodamų sklypų",
  land_rent: "nuomojamų sklypų",
  premises_sale: "parduodamų patalpų",
  premises_rent: "nuomojamų patalpų",
  garage_sale: "parduodamų garažų",
  garage_rent: "nuomojamų garažų",
  parking_sale: "parduodamų parkavimo vietų",
  parking_rent: "nuomojamų parkavimo vietų",
  apartment_complex: "naujų daugiabučių projektų",
};

function ResultControlPanel() {
  const { state, dispatch, userState, dispatchUser } = useGlobalContext();
  const history = useHistory();
  const params = useParamsFromUrl();
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(
    params?.favorites_only || false
  );
  const [openSaveSearchDialog, setOpenSaveSearchDialog] = useState(false);

  useEffect(() => {
    setShowFavoritesOnly(params?.favorites_only || false);
  }, [params?.favorites_only]);

  function handleFavorites() {
    if (!userState.isAuthenticated) {
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { openLoginDialog: true },
      });
      return;
    }
    let newParams = { ...params, favorites_only: !showFavoritesOnly };
    delete newParams.page;
    reloadAds(newParams, dispatch, history);
    setShowFavoritesOnly(!showFavoritesOnly);
  }

  function handleSaveSearch() {
    if (!userState.isAuthenticated) {
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { openLoginDialog: true },
      });
      return;
    }
    setOpenSaveSearchDialog(true);
  }

  return (
    <>
      {/* {state.adCardsLoaded && !state?.adCardsLoading && ( */}
      <Paper
        sx={{
          width: "100%",
          border: "solid lightgrey 1px",
          backgroundColor: blue[100],
          // mx: 0.5,
          mt: 2,
          p: 1,
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="left"
          sx={{ mb: 2 }}
        >
          <Grid
            container
            item
            alignItems="center"
            justifyContent="space-between"
          >
            {(!state.adCardsLoaded || state?.adCardsLoading) && (
              <Typography variant="body1" sx={{ mr: 2, fontWeight: "bold" }}>
                Kraunama...
              </Typography>
            )}
            {!!state.adCardsLoaded &&
              !state.adCardsLoading &&
              !!state?.resultCount && (
                <>
                  <Typography
                    variant="body1"
                    sx={{ mr: 2, fontWeight: "bold" }}
                  >
                    Rasta {adNames[params.ad_type]}: {state.resultCount}.
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <PageSelector />
                  </Box>
                </>
              )}
            {!!state.adCardsLoaded &&
              !state.adCardsLoading &&
              !state?.resultCount && (
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Paiešką atitinkančių skelbimų nerasta.
                </Typography>
              )}
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            justifyContent="space-between"
            sx={{ mt: 1 }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" sx={{ mr: 1 }}>
                Išsaugoti paieškos nustatymus
              </Typography>
              <IconButton
                disabled={showFavoritesOnly}
                onClick={handleSaveSearch}
                size="small"
                sx={{ border: "solid grey 1px" }}
              >
                <SaveIcon color="default" fontSize="small" />
              </IconButton>
              {openSaveSearchDialog && userState.isAuthenticated && (
                <SaveSearchDialog
                  open={openSaveSearchDialog}
                  setOpen={setOpenSaveSearchDialog}
                />
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" sx={{ mr: 1 }}>
                Rodyti tik įsimintus
              </Typography>
              <IconButton
                size="small"
                onClick={handleFavorites}
                disabled={!!state.adCardsLoading}
                sx={{ border: "solid grey 1px" }}
              >
                <FavoriteIcon
                  sx={{
                    color: showFavoritesOnly ? red[500] : "default",
                    opacity: showFavoritesOnly ? 1 : 0.5,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        <Grid container justifyContent="left" alignItems="center">
          <SortSelector />
          <PreferenceChip />
          {/* <Button
          size="small"
          variant="outlined"
          color="inherit"
          startIcon={<FormatListNumberedIcon />}
        >
          <Typography variant="caption">20</Typography>
        </Button> */}
        </Grid>
      </Paper>
      {/* )} */}
    </>
  );
}
export default ResultControlPanel;
