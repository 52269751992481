import React, { useRef, useState, useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import { Typography, Chip, Box, DialogTitle } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import { useHistory } from "react-router-dom";
import { PREFERENCE_ARRAY } from "../../../../utils/defaults";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import reloadAds from "../../../../utils/getUrlFromParams";
import PreferenceSelector from "./PreferenceSelector";
import DialogOrMenu from "../../MainFilters/DetailedFilter/DialogOrMenu";
import { blue, grey } from "@mui/material/colors";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";

// Main PreferenceSelector component
export default function PreferenceChip() {
  const { state, dispatch, userState, dispatchUser } = useGlobalContext();
  const { isSmallOrMobile } = useCustomMediaQuery();

  const chipRef = useRef(null);
  const formikRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const params = useParamsFromUrl();

  const chipLabel = useMemo(() => {
    let chipLabel = "Mano poreikiai";
    let selectedPreferencesCount = PREFERENCE_ARRAY.filter(
      (preference) => !!params[preference.param]
    ).length;
    if (!!selectedPreferencesCount) {
      chipLabel = `Mano poreikiai (${selectedPreferencesCount})`;
      // chipLabel = `${selectedPreferencesCount} ${
      //   selectedPreferencesCount === 1 ? "poreikis" : "poreikiai"
      // }`;
    }
    return chipLabel;
  }, [params]);

  useEffect(() => {
    // if user has selected ordering by preferences but no preferences are selected, open the dialog
    if (params?.ordering === "-preferences" && chipLabel === "Mano poreikiai") {
      isSmallOrMobile
        ? setAnchorEl(true)
        : setAnchorEl(state.leftMenuRef?.current);
      const snackbar = userState.snackbar;
      snackbar.open = true;
      snackbar.message =
        "Poreikiai nėra pasirinkti. Pasirinkite savo poreikius";
      snackbar.severity = "info";
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { snackbar },
      });
    }
  }, [chipLabel, params?.ordering, state.leftMenuRef]);

  function handleSubmit(formikValues) {
    setAnchorEl(null);
    if (!!formikRef?.current?.dirty) {
      // console.log("formikValues from PreferenceChip", formikValues);
      let newParams = { ...params, ...formikValues };
      delete newParams.page;
      const orderingChanged = changeOrdering(newParams);
      if (orderingChanged) return;
      reloadAds(newParams, dispatch, history);
      return;
    }
    changeOrdering(params);
  }

  function changeOrdering(params) {
    let newParams = params;
    let selectedPreferencesCount = PREFERENCE_ARRAY.filter(
      (preference) => !!params[preference.param]
    ).length;
    // change to default ordering, when no preferences are selected
    if (!selectedPreferencesCount && newParams?.ordering === "-preferences") {
      newParams = { ...newParams, ordering: "" };
      delete newParams.page;
      const snackbar = userState.snackbar;
      snackbar.open = true;
      snackbar.message = 'Rikiavimas pakeistas į "Naujausi viršuje"';
      snackbar.severity = "warning";
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { snackbar },
      });
      reloadAds(newParams, dispatch, history);
      return true;
    }
    return false;
  }

  function handleXClick() {
    const emptyPreferenceParams = {};
    PREFERENCE_ARRAY.forEach((preference) => {
      emptyPreferenceParams[preference.param] = 0;
    });
    let newParams = { ...params, ...emptyPreferenceParams };
    const orderingChanged = changeOrdering(newParams);
    if (orderingChanged) return;
    reloadAds(newParams, dispatch, history);
  }

  // console.log("anchorEl from PreferenceChip", anchorEl);

  return (
    <>
      <Chip
        label={<Typography variant="body2">{chipLabel}</Typography>}
        onClick={(e) =>
          setAnchorEl(state.leftMenuRef?.current || e.currentTarget)
        }
        onDelete={chipLabel === "Mano poreikiai" ? null : handleXClick}
        variant="outlined"
        color={chipLabel === "Mano poreikiai" ? "default" : "primary"}
        sx={{ borderRadius: "3px", border: "solid grey 1px" }}
        ref={chipRef}
      />
      {anchorEl && (
        <DialogOrMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          formikRef={formikRef}
          // visibility={visibility}
        >
          <DialogTitle
            sx={{
              backgroundColor: blue[100],
              display: "flex",
              justifyContent: "center",
            }}
          >
            Mano poreikiai renkantis NT objektą
          </DialogTitle>
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              borderBottom: "1px solid lightgrey",
              backgroundColor: grey[50],
            }}
          >
            <PreferenceSelector
              formikRef={formikRef}
              handleSubmit={handleSubmit}
              setAnchorEl={setAnchorEl}
              // visibility={visibility}
              // setVisibility={setVisibility}
            />
          </Box>
          <Box
            sx={{
              my: 2,
              pr: 3,
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                changeOrdering(params);
                setAnchorEl(null);
              }}
              sx={{ mr: 1 }}
            >
              Atšaukti
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => formikRef.current.submitForm()}
            >
              Gerai
            </Button>
          </Box>
        </DialogOrMenu>
      )}
    </>
  );
}
