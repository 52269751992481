import React, { useEffect, useState } from "react";
import { Typography, Button, IconButton, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import { useGlobalContext } from "../../../context";
import { fetchAdCards, fetchAds } from "../../MainPage/fetchOrSendMainAppData";
import getUrlFromParams from "../../../utils/getUrlFromParams";
import { useLocation, useParams, useHistory } from "react-router-dom";

export default function NavigatePreviousNext({ setActiveSlide }) {
  const { adId } = useParams();

  const history = useHistory();
  const location = useLocation();
  const { state, dispatch, routerState, dispatchRouter } = useGlobalContext();
  const { isSmallOrMobile } = useCustomMediaQuery();

  const resultCount = state.resultCount;
  const resultsPerPage = state.resultsPerPage;
  const currentPage = +state?.page || 1;
  const adIndex = state.ads.findIndex((item) => item.id === +adId);
  const [resultIndex, setResultIndex] = useState(
    (currentPage - 1) * resultsPerPage + adIndex + 1
  );

  // useEffect(() => {
  //   setResultIndex((currentPage - 1) * resultsPerPage + adIndex + 1);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [adIndex, currentPage, resultsPerPage]);

  async function handleNavigation(step) {
    setActiveSlide(0);
    setResultIndex(resultIndex + step);
    const newAdIndex = adIndex + step;
    let newAd = state.ads[newAdIndex];
    if (!!newAd) {
      history.push(`/skelbimas/${newAd.properties.slug}/${newAd.id}`);
      return;
    }

    const newParams = { ...routerState.appParams, page: currentPage + step };
    fetchAdCards(newParams, dispatch, dispatchRouter);
    const ads = await fetchAds(newParams, dispatch);
    newAd = step > 0 ? ads[0] : ads[ads.length - 1];
    dispatch({ type: "CHANGE_APP_STATE", payload: { ad: newAd } });
    // setResultIndex(resultIndex + step);
    newParams.page = currentPage + step;
    const urlObj = getUrlFromParams(newParams);
    const mainAppUrlString = urlObj.pathname + urlObj.search;
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { appParams: newParams, mainAppUrlString },
    });
    history.push(`/skelbimas/${newAd.properties.slug}/${newAd.id}`);
  }

  function handleBackToList() {
    history.push(routerState.mainAppUrlString);
  }

  // console.log("resultIndex from Navigate", resultIndex, adIndex);
  // console.log("state ads", state.ads);
  // console.log("history from NavigatePreviousNext", history, location);
  // console.log("routerState from NavigatePreviousNext", routerState);

  return (
    <Box sx={{ px: 1, border: "none", display: "flex" }}>
      {isSmallOrMobile ? (
        <IconButton onClick={handleBackToList} size="small" sx={{ mx: 0.5 }}>
          <ArrowBackIcon fontSize="small" color="action" />
        </IconButton>
      ) : (
        <Button
          size="small"
          variant="outlined"
          onClick={handleBackToList}
          sx={{ borderColor: grey[500], mx: 1 }}
          startIcon={<ArrowBackIcon fontSize="small" color="action" />}
        >
          <Typography color="text.primary" variant="caption" sx={{ mt: "2px" }}>
            Į sąrašą
          </Typography>
        </Button>
      )}

      {!!resultIndex && (
        <Box>
          {isSmallOrMobile ? (
            <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
              <IconButton
                onClick={() => handleNavigation(-1)}
                size="small"
                sx={{ mx: 0.5 }}
                disabled={resultIndex === 1}
              >
                <ChevronLeftIcon
                  fontSize="small"
                  color={resultIndex === 1 ? "lightgrey" : "action"}
                />
              </IconButton>
              <Typography
                variant="caption"
                color="text.primary"
                sx={{ pt: 0.2 }}
              >
                {resultIndex} iš {resultCount}
              </Typography>
              <IconButton
                onClick={() => handleNavigation(1)}
                size="small"
                sx={{ mx: 0.5 }}
                disabled={resultIndex === resultCount}
              >
                <NavigateNextIcon
                  fontSize="small"
                  color={resultIndex === resultCount ? "lightgrey" : "action"}
                />
              </IconButton>
            </Stack>
          ) : (
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <Button
                size="small"
                variant="outlined"
                onClick={() => handleNavigation(-1)}
                sx={{ borderColor: grey[500] }}
                startIcon={
                  <ChevronLeftIcon
                    fontSize="small"
                    color={resultIndex === 1 ? "lightgrey" : "action"}
                  />
                }
                disabled={resultIndex === 1}
              >
                <Typography
                  color={resultIndex === 1 ? "lightgrey" : "text.primary"}
                  variant="caption"
                  sx={{ mt: "2px" }}
                >
                  Ankstesnis
                </Typography>
              </Button>
              <Typography variant="caption" color="text.primary">
                {resultIndex} iš {resultCount}
              </Typography>
              <Button
                size="small"
                variant="outlined"
                onClick={() => handleNavigation(1)}
                sx={{ borderColor: grey[500] }}
                endIcon={
                  <NavigateNextIcon
                    fontSize="small"
                    color={resultIndex === resultCount ? "lightGrey" : "action"}
                  />
                }
                disabled={resultIndex === resultCount}
              >
                <Typography
                  color={
                    resultIndex === resultCount ? "lightGrey" : "text.primary"
                  }
                  variant="caption"
                  sx={{ mt: "2px" }}
                >
                  Kitas
                </Typography>
              </Button>
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
}
